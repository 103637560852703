<template>
	<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class='modal-content' v-if='action_running'>
				<div class="modal-body">
					<h5 class='text-info'>Suspending User</h5>
					Hold on tight. We are removing user access to this event.
				</div>
			</div>
			<div class="modal-content" v-if='!action_running'>
				<div class="modal-header">
					<h5 class="modal-title">Suspend User</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
				</div>
				<div class="modal-body">
					<h5 class='text-danger'>Are you sure you want to suspend <b>{{user.name}}</b>?</h5>
					By suspending this user:
					<ul>
						<li>User will no longer be able to access platform.</li>
						<li>User may be re-activated at any time.</li>
					</ul>
				</div>
				<ErrorMessage :message='error' v-if='error.length > 0' />
				<div class="modal-footer">
					<button type="button" class="btn btn-danger" @click='action'>Suspend</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import store from "@/store";

	import PermissionService from "@/common/permission.service";

	import { SUSPEND_USER } from "@/store/actions.type";

	import ErrorMessage from "@/components/General/ErrorMessage";

	export default {
		name: 'DeleteUser',
		components: {
			ErrorMessage
		},
		props: {
			user: {
				type: Object,
				required: true
			}
		},
		data(){
			return {
				error: '',
				action_running: false
			};
		},
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			close(){
				if(this.action_running) return;
				this.$emit('close');
			},
			action(){
				if(this.action_running) return;
				this.action_running = true;
				store.dispatch(SUSPEND_USER, this.user.user_id).then(() => {
					this.$emit('success');
				}).catch(message => {
					this.error = message;
					this.action_running = false;
				}).finally(() => {
				});
			}
		}
	}
</script>