<template>
	<!-- Add User Modal -->
	<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class='modal-content' v-if='action_running'>
				<div class="modal-body">
					<h5 class='text-info'>Updating User</h5>
					Hold on tight. We are updating user details.
				</div>
			</div>
			<div class="modal-content" v-if='!action_running'>
				<div class="modal-header">
					<h5 class="modal-title">Edit a User</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
				</div>
				<div class="modal-body pt-1">
					<div class='row mb-4'>
						<div class='col-md-6'>
							<div class="form-group mb-0">
								<label>New Password </label>
								<input type='password' :class="{'is-invalid': get_error('pass1').length > 0}" class="form-control" v-model='user.pass1' placeholder='*********'/>
							</div>
							<span v-show="get_error('pass1').length > 0" class="text-danger">{{ get_error('pass1') }}</span>
						</div>
						<div class='col-md-6'>
							<div class="form-group mb-0">
								<label>Confirm Password</label>
								<input type='password' :class="{'is-invalid': get_error('pass2').length > 0}" class="form-control" v-model='user.pass2' placeholder='*********'/>
							</div>
							<span v-show="get_error('pass2').length > 0" class="text-danger">{{ get_error('pass2') }}</span>
						</div>
					</div>
					<div class='row mt-3'>
						<div class='col-md-12'>
							<div class="form-group mb-0">
								<label>Role <sup class='text-danger'>*</sup></label>
								<select class="form-control" :class="{'is-invalid': get_error('role').length > 0}" v-model='user.role'>
									<option value="">Select Role</option>
									<option value="user">User</option>
									<option value="employee">Employee</option>
									<option value="admin">Admin</option>
								</select>
								<i class="select-down zwicon-chevron-down display-4"></i>
							</div>
							<span v-show="get_error('role').length > 0" class="text-danger">{{ get_error('role') }}</span>
						</div>
					</div>
				</div>
				<ErrorMessage :message='error' v-if='error.length > 0' />
				<div class="modal-footer">
					<button type="button" class="btn btn-danger" @click='action'>Edit</button>
				</div>
			</div>
		</div>
	</div>
	<!-- End Edit User Modal -->
</template>

<script>
	import store from "@/store";

	import PermissionService from "@/common/permission.service";

	import { EDIT_USER } from "@/store/actions.type";

	import ErrorMessage from "@/components/General/ErrorMessage";

	export default {
		name: 'EditUser',
		components: {
			ErrorMessage
		},
		props: {
			oldUser: {
				type: Object,
				required: true
			}
		},
		data(){
			return {
				error: '',
				action_running: false,
				user: {
					user_id: this.oldUser.user_id,
					name: this.oldUser.name,
					email: this.oldUser.email,
					role: this.oldUser.role,
					pass1: "",
					pass2: "",
				},
				errors: {}
			};
		},
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			close(){
				if(this.action_running) return;
				this.$emit('close');
			},
			action(){
			
				this.validate_add_user();
				
			},
			validate_add_user(){
				this.errors  = {
					name: '',
					email: '',
					role: ''
				};
				if(this.user.role == ""){
					this.errors.role = "Please select user's role.";
					return;
				}
				this.edit_user();
			},
			edit_user(){
				if(this.action_running) return;
				this.action_running = true;
				store.dispatch(EDIT_USER, {
					"user_id": this.user.user_id,
					"role": this.user.role,
					"pass1": this.user.pass1,
					"pass2": this.user.pass2
				}).then(() => {
					this.$emit('success');
				}).catch(message => {
					this.error = message;
					this.action_running = false;
				}).finally(() => {
				});
			}
		}
	}
</script>