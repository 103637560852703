<template>
	<div>

		<DeleteUser :user='action_user' v-if='show_delete && getPermission("users", "suspend")' @close='show_delete = false;' @success='user_deleted'/>
		<ReactivateUser :user='action_user' v-if='show_activate && getPermission("users", "suspend")' @close='show_activate = false;' @success='user_activated'/>
		<EditUser :old-user='action_user' v-if='show_edit && getPermission("users", "edit")' @close='show_edit = false;' @success='user_updated'/>
		<AddUser v-if='modal_add_user_toggle && getPermission("users", "add")' @close='modal_add_user_toggle = false;' @success='user_added'/>

		<!-- Filter User Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true" v-if='show_filter_modal'>
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Filter User List</h5><i class="modal-close zwicon-close pull-right display-4" @click='close_filters'></i>
					</div>
					<div class="modal-body">
						<div class='row mt-0'>
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>Search Users</label>
									<input class="form-control" v-model='filter.search' placeholder='Search Name or Email...'/>
								</div>
							</div>
						</div>

						<div class='row mt-3'>
							<div class='col-md-6'>
								<div class="form-group mb-0">
									<label>With Role</label>
									<select class="form-control" v-model='filter.role'>
										<option value="">Select Role</option>
										<option value="user">User</option>
										<option value="employee">Employee</option>
										<option value="admin">Admin</option>
										
									</select>
									<i class="select-down zwicon-chevron-down display-4"></i>
								</div>
							</div>
							<div class='col-md-6'>
								<div class="form-group mb-0">
									<label>User Status</label>
									<select class="form-control" v-model='filter.status'>
										<option value="">Select Status</option>
										<option value="active">Active Users</option>
										<option value="suspended">Suspended Users</option>
									</select>
									<i class="select-down zwicon-chevron-down display-4"></i>
								</div>
							</div>
						</div>

						<hr class='mt-4'/>

						<div class='row mt-3'>
							<div class='col-md-6'>
								<div class="form-group mb-0">
									<label>Sort By</label>
									<select class="form-control" v-model='filter.sort_by'>
										<option value="user_id">User ID</option>
										<option value="name">Name</option>
										<option value="email">Email</option>
									</select>
									<i class="select-down zwicon-chevron-down display-4"></i>
								</div>
							</div>
							<div class='col-md-6'>
								<div class="form-group mb-0">
									<label>Sort Direction</label>
									<select class="form-control" v-model='filter.sort_dir'>
										<option value="ASC">Ascending</option>
										<option value="DESC">Descending</option>
									</select>
									<i class="select-down zwicon-chevron-down display-4"></i>
								</div>
							</div>
						</div>

					</div>
					<ErrorMessage :message='filter_error' v-if='filter_error.length > 0' />
					<div class="modal-footer">
						<button type="button" class="btn btn-danger" @click='apply_filters'>Apply Filters</button>
						<button type="button" class="btn btn-secondary" @click='clear_filters'>Clear Filters</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End Add User Modal -->

		<SuccessMessage :message='success' v-if='success.length > 0'/>

		<div class='row'>
			<div class='col-12'>
				<div class="card mt-3">
					<div class="actions">
						<button class="btn btn-primary btn--icon-text ml-3" v-tooltip="'Add a User'" @click='open_add_user_modal' v-if='getPermission("users", "add")'><i class="zwicon-plus"></i> Add User</button>
					</div>
					<div class="card-body pb-4">
						<h4 class="card-title">User List</h4>
						<h6 class="card-subtitle">Search, filter and manage a list of the users who have access to your event.</h6>
						
						<div class="dataTables__top">
							<div id="data-table_filter" class="dataTables_filter">
								<label>Search:<input type="search" class="" placeholder="Quickly search by name or email..." v-on:keyup.enter="apply_filters" v-model='filter.search'></label>
							</div>
							<div class="dataTables_length">
								<a class="btn btn-primary pl-5 btn-block text-left" @click='open_filters' role="button">View Filters</a>
							</div>
						</div>
						
						<div class='mb-3' v-if='has_filters'>
							<div class='badge badge-info mr-2' v-if='searched_filter.search !== ""'><b>Searched</b>: {{searched_filter.search}} <a class='badge-secondary badge p-1 ml-2 text-white' @click='clear_filter("search")'>X</a></div>
							<div class='badge badge-info mr-2' v-if='searched_filter.role !== ""'><b>Role</b>: {{searched_filter.role}} <a class='badge-secondary badge p-1 ml-2 text-white' @click='clear_filter("role")'>X</a></div>
							<div class='badge badge-info mr-2' v-if='searched_filter.status !== ""'><b>User Status</b>: {{searched_filter.status}} <a class='badge-secondary badge p-1 ml-2 text-white' @click='clear_filter("status")'>X</a></div>
						</div>

						<div class='list-loading' v-if='list_loading'>
							Loading users...
						</div>
						<table class="table table-sm mb-0">
							<thead class="thead-dark">
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th>Role</th>
									<th>Companies</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for='user_item in users.list' v-bind:key="user_item.user_id">
									<td>{{user_item.name}}<span v-if='!user_item.active' class='text-warning'> (Suspended)</span><span v-if='user_item.email == user.email' class='text-success'> (You)</span></td>
									<td>{{user_item.email || "-"}}</td>
									<td style='text-transform: capitalize;'>{{user_item.role || "-"}}</td>
									<td v-if="user_item.role == 'user'">
										<div v-for="company in user_item.companies" v-bind:key="company.company_id">{{company.name}}</div>
									</td>
									<td v-if="user_item.role != 'user'">- All Companies -</td>
									<td v-if='user_item.user_id > 0 && user_item.email == user.email || !(getPermission("users", "add") || getPermission("users", "edit") || getPermission("users", "suspend"))'></td>
									<td style='text-align: right; position: relative;' v-if='user_item.user_id > 0 && user_item.email != user.email && (getPermission("users", "add") || getPermission("users", "edit") || getPermission("users", "suspend"))' @click='toggle_v_dropdown(user_item.user_id)'>
										<button class="btn btn-primary btn-sm" v-tooltip="'Toggle User Menu'"><i class="zwicon-more-h"></i> Actions</button>
										<div class="dropdown-menu dropdown-menu-right" v-if='active_item.toString() == user_item.user_id.toString()' v-click-outside="toggle_v_dropdown"> <!--  v-if='user_dd["x"+user_item.user_id]' -->
											<a class="dropdown-item" @click='edit_user(user_item)' v-if='getPermission("users", "edit")'>Edit</a>
											<a class="dropdown-item" @click='delete_user(user_item)' v-if='getPermission("users", "suspend") && user_item.active'>Suspend</a>
											<a class="dropdown-item" @click='activate_user(user_item);' v-if='getPermission("users", "suspend") && !user_item.active'>Activate</a>
										</div>
									</td>
								</tr>
								<tr v-if='users.list !== undefined && users.list.length == 0'><td COLSPAN='5'>No users were found that match your current filters.</td></tr>
							</tbody>
						</table>
						<div class='row mt-2' v-if='users.pagination !== undefined'>
							<div class='col-md-6 pt-2'>Showing {{(users.pagination.per_page*users.pagination.page)+1}} to {{pagination_max}} of {{users.pagination.total_items}} users.</div>
							<div class='col-md-6'>
								<div class="ml-auto" v-if='pages.length > 1'>
									<Pagination @set_page="view_page" :current_page='users.pagination.page' :pages='pages' />
								</div>
							</div>
						</div>
						
						
					</div>
				</div>

			</div>
		</div>
		
	</div>
</template>

<script>

	import { mapGetters } from "vuex";
	import store from "@/store";
	import router from "@/router";

	import { ON_LOAD, OFF_LOAD, LOAD_USER_LIST } from "@/store/actions.type";

	import PermissionService from "@/common/permission.service";

	import SuccessMessage from "@/components/General/SuccessMessage";
	import ErrorMessage from "@/components/General/ErrorMessage";
	import Pagination from '@/components/General/Pagination';

	import DeleteUser from '@/views/Users/Modals/DeleteUser';
	import ReactivateUser from '@/views/Users/Modals/ReactivateUser';
	import AddUser from '@/views/Users/Modals/AddUser';
	import EditUser from '@/views/Users/Modals/EditUser';

	export default {
		name: 'UserList',
		components: {
			ErrorMessage,
			SuccessMessage,
			Pagination,
			DeleteUser,
			ReactivateUser,
			AddUser,
			EditUser,
		},
		data(){
			return {
				modal_add_user_toggle: false,
				list_menu_toggle: false,
				show_filter_modal: false,

				action_user: {},
				show_delete: false,
				show_activate: false,
				show_edit: false,
				show_permissions: false,
				show_notifications: false,

				list_loading: true,
				active_item: '',
				has_filters: false,
				filter: {},
				searched_filter: {},
				usr: {},
				errors: {},
				add_error: "",
				filter_error: '',
				success: ""
			};
		},
		beforeMount() {
			this.reset_filter_form();
			store.dispatch(ON_LOAD);
			this.load_list(function(){
				store.dispatch(OFF_LOAD);
			}, function(){
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load user list." }});
			});
		},
		computed: {
			pagination_max(){
				var page_max = (this.users.pagination.per_page*(this.users.pagination.page+1));
				if(page_max > this.users.pagination.total_items)  page_max = this.users.pagination.total_items;
				return page_max;
			},
			pages(){
				let page_list = [];
				for(var i = 0;i < Math.ceil(this.users.pagination.total_items / this.users.pagination.per_page);i++){
					page_list.push((i*1)+1);
				}
				return page_list;
			},
			...mapGetters(['user', 'loading', 'users'])
		},
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			user_deleted(){
				this.show_delete = false;
				this.action_user = {};
				this.load_list(() => {
					this.success = 'User has been suspended.';
				}, function(){
					this.success = '';
				});
			},
			user_activated(){
				this.show_activate = false;
				this.action_user = {};
				this.load_list(() => {
					this.success = 'User has been re-activated.';
				}, function(){
					this.success = '';
				});
			},
			user_updated(){
				this.show_edit = false;
				this.action_user = {};
				this.load_list(() => {
					this.success = 'User has been updated.';
				}, function(){
					this.success = '';
				});
			},
			user_added(){
				this.modal_add_user_toggle = false;
				this.action_user = {};
				this.load_list(() => {
					this.success = 'User has been added.';
				}, function(){
					this.success = '';
				});
			},
			permissions_updated(){
				this.show_permissions = false;
				this.success = "User permissions have been updated";
			},
			edit_user(user){
				this.action_user = user;
				this.show_edit = true;
			},
			delete_user(user){
				this.action_user = user;
				this.show_delete = true;
			},
			activate_user(user){
				this.action_user = user;
				this.show_activate = true;
			},
			permissions_user(user){
				this.action_user = user;
				this.show_permissions = true;
			},
			load_list(success_fn, failure_fn){
				this.list_loading = true;
				this.filter_error = "";
				console.log('load list')
				store.dispatch(LOAD_USER_LIST, this.filter).then(data => {
					success_fn(data);
				}).catch(message => {
					failure_fn(message);
				}).finally(() => {
					this.list_loading = false;
				});
			},
			toggle_v_dropdown(id){
				if(id === undefined) id = '';
				if(this.active_item == id) this.active_item = '';
				else this.active_item = id;
			},
			open_filters(){
				this.show_filter_modal = true;
			},
			close_filters(){
				this.show_filter_modal = false;
			},
			view_page(page){
				this.filter.page = page;
				this.load_list(this.close_filters, function(){
					this.success = '';
					this.filter_error = '';
				});
			},
			clear_filter(key){
				this.filter[key] = '';
				this.apply_filters();
			},
			apply_filters(){
				this.searched_filter = Object.assign({}, this.filter);
				this.has_filters = false;
				['search', 'role', 'status'].forEach(key => {
					if(this.filter[key] !== "") this.has_filters = true;
				})
				this.load_list(this.close_filters, function(){
					this.success = '';
					this.filter_error = '';
				});
			},
			clear_filters(){
				this.reset_filter_form();
				this.apply_filters();
			},
			open_add_user_modal(){
				this.modal_add_user_toggle = true;
			},
			close_add_user_modal(){
				this.modal_add_user_toggle = false;
			},
			toggle_list_menu(){
				this.list_menu_toggle = !this.list_menu_toggle;
			},
			reset_filter_form(){
				this.filter = {
					page: 0,
					search: '',
					role: '',
					status: '',
					sort_by: 'user_id',
					sort_dir: 'DESC'
				};
			}
		}
	}
</script>