<template>
	<!-- Add Voter Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class='modal-content' v-if='add_running'>
					<div class="modal-body">
						<h5 class='text-info'>Sending Invite</h5>
						Sending an invite email to user...
					</div>
				</div>
				<div class="modal-content" v-if='!add_running'>
					<div class="modal-header">
						<h5 class="modal-title">Add a User</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
					</div>
					<div class="modal-body">
						<div class='row'>
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>Full Name <sup class='text-danger'>*</sup></label>
									<input :class="{'is-invalid': get_error('name').length > 0}" class="form-control" v-model='user.name' placeholder='Ex. Jane Smith'/>
								</div>
								<span v-show="get_error('name').length > 0" class="text-danger">{{ get_error('name') }}</span>
							</div>							
						</div>
						<div class='row mt-3'>
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>Email Address <sup class='text-danger'>*</sup></label>
									<input :class="{'is-invalid': get_error('email').length > 0}" class="form-control" v-model='user.email' placeholder='Ex. jsmith@internet.com'/>
								</div>
								<span v-show="get_error('email').length > 0" class="text-danger">{{ get_error('email') }}</span>
							</div>
						</div>
						<div class='row mt-3'>
							<div class='col-md-6'>
								<div class="form-group mb-0">
									<label>Password <sup class='text-danger'>*</sup></label>
									<input type='password' :class="{'is-invalid': get_error('pass1').length > 0}" class="form-control" v-model='user.pass1' placeholder='*********'/>
								</div>
								<span v-show="get_error('pass1').length > 0" class="text-danger">{{ get_error('pass1') }}</span>
							</div>
							<div class='col-md-6'>
								<div class="form-group mb-0">
									<label>Confirm Password <sup class='text-danger'>*</sup></label>
									<input type='password' :class="{'is-invalid': get_error('pass2').length > 0}" class="form-control" v-model='user.pass2' placeholder='*********'/>
								</div>
								<span v-show="get_error('pass2').length > 0" class="text-danger">{{ get_error('pass2') }}</span>
							</div>
						</div>
						<div class='row mt-3'>
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>Role <sup class='text-danger'>*</sup></label>
									<select class="form-control" :class="{'is-invalid': get_error('role').length > 0}" v-model='user.role'>
										<option value="">Select Role</option>
										<option value="user">User</option>
										<option value="employee">Employee</option>
										<option value="admin">Admin</option>
									</select>
								</div>
								<span v-show="get_error('role').length > 0" class="text-danger">{{ get_error('role') }}</span>
							</div>
						</div> 
						<div class='row mt-3' v-if="user.role == 'user'">
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>User Company <sup class='text-danger'>*</sup></label>
									<select id="temp-item-company" class="form-control" v-model='user.company_id'>
										<option value="any" selected>Select Company</option>
										<option v-for="company in company_list.list" v-bind:key="company" :value="company.company_id">{{company.name}}</option>
									</select>		
								</div>
							</div>
						</div>
					</div>
					<ErrorMessage :message='error' v-if='error.length > 0' />
					<div class="modal-footer">
						<button type="button" class="btn btn-danger" @click='action'>Invite</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End Add Voter Modal -->
</template>

<script>

	import store from "@/store";
	import router from "@/router";
	import { mapGetters } from "vuex";
	import PermissionService from "@/common/permission.service";

	import { ADD_USER } from "@/store/actions.type";
	import { LOAD_COMPANY_LIST } from "@/store/actions.type";

	import ErrorMessage from "@/components/General/ErrorMessage";

	export default {
		name: 'AddUser',
		components: {
			ErrorMessage
		},
		props: {},
		data(){
			return {
				error: '',
				add_running: false,
				user: {
					name: '',
					email: '',
					role: '',
					pass1: '',
					pass2: '',
					company_id: ''
				},
				errors: {
					name: '',
					email: '',
					role: '',
					pass1: '',
					pass2: ''
				}
			};
		},
        beforeMount() {
			this.load_company_list(function(){
                
			}, function(){
                
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load company list." }});
			});
		},
		computed:{
			...mapGetters(['company_list'])
        
		},
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			close(){
				if(this.add_running) return;
				this.$emit('close');
			},
			action(){
				this.validate_add_user();
				
			},
			validate_add_user(){
				this.errors  = {
					name: '',
					email: '',
					role: ''
				};
				if(this.user.name.length < 3){
					this.errors.name = "Please enter user's full name.";
					return;
				}
				
				if(this.user.email.length < 1 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.user.email)){
					this.errors.email = "Please enter a valid email address.";
					return;
				}
				if(this.user.pass1.length < 6){
					this.errors.pass1 = "Please enter password at least 6 characters in length";
					return;
				}
				if(this.user.pass1 != this.user.pass2){
					this.errors.pass2 = "Your passwords do not match!";
					return;
				}
				if(!['user', 'employee', 'admin'].includes(this.user.role)){
					this.errors.role = "Please select user's role.";
					return;
				}
				this.add_user();
			},
			add_user(){
				this.error = '';
				if(this.add_running) return;
				this.add_running = true;
				store.dispatch(ADD_USER, this.user).then(() => {
					this.$emit('success');
				}).catch(message => {
					if(message !== undefined) this.error = message;
					else this.error = "An error occured while attempting to create user.";
					this.add_running = false;
				}).finally(() => {
				});
			},load_company_list(success_fn, failure_fn = () => {}){
				this.list_loading = true;
				this.filter_error = "";
				
				store.dispatch(LOAD_COMPANY_LIST).then(data => {
					success_fn(data);
				}).catch((message => {
					failure_fn(message);
				})).finally(() => {
					this.list_loading = false;
				});
			}
		}
	}
</script>